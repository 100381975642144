import logo from '../assets/img/logos/Jazeera.png';
import logoWhite from '../assets/img/logos/Jazeera.png';
import rollingBanner1Web from '../assets/img/banners/jazeera/HDDesktop_WL_Home [1920x400].jpg';
import rollingBanner2Web from '../assets/img/homeBanner/RollingBanner2.jpg';
import rollingBanner3Web from '../assets/img/homeBanner/RollingBanner3.jpg';
import rollingBanner4Web from '../assets/img/homeBanner/RollingBanner4.jpg';
import rollingBanner5Web from '../assets/img/homeBanner/RollngBanner5.jpg';
import rollingBanner1Mob from '../assets/img/banners/jazeera/Phone_WL_Home [Banner1].jpg';
import rollingBanner1Tab from '../assets/img/banners/jazeera/iPad_WL_Home [Banner4].jpg';
import rollingBanner2Mob from '../assets/img/homeBanner/RollingBanner2MobileWeb.jpg';
import rollingBanner3Mob from '../assets/img/homeBanner/RollingBanner3MobileWeb.jpg';
import rollingBanner4Mob from '../assets/img/homeBanner/RollingBanner4MobileWeb.jpg';
import rollingBanner5Mob from '../assets/img/homeBanner/RollingBanner5MobileWeb.jpg';
import localization from '../localization';
var banner = require('../assets/img/banners/airserbia_banner.jpg');
var favicon = require('../assets/img/favicons/jazeera.ico');

export default {
  name: 'Jazeera',
  clientName: 'Jazeera',
  config: {
    showMapsByDefault: false,
    googleAnalyticsKey: 'G-YHSQWPMHCT',
    clientGAKey: 'G-YHSQWPMHCT',
    GTMID: 'G-YHSQWPMHCT',
    errorReportingUrl: 'https://a84eb116e71549f8b5099aaae2ed1cbf@o1309758.ingest.sentry.io/6558225',
    usePrimaryColoredBanner: false,
    overrideActivityProviderText: 'Activity brought to you by JazeeraAirways',
  },
  meta: {
    title: 'TripAdmit - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#004A97',
    secondary: '#0D84C7',
    inactive: '#BCBCBC',
  },
  fonts: {
    colors: {
      primary: '#004A97',
      secondary: '#0D84C7',
      inactive: '#BCBCBC',
    },
    primary: 'MontserratRegular',
    regular: 'MontserratRegular',
    light: 'MontserratRegular',
    bold: 'MontserratBold',
  },
  styles: {
    airserbiaButton: {
      borderRadius: '4px!important',
    },
    pickerControlButton: {
      background: '#0072CE',
      borderRadius: '4px',
      width: '32px',
      height: '32px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
    pickerCounter: {
      background: 'none',
      border: '0',
      width: '36px',
      height: '32px',
      padding: '0',
      textAlign: 'center',
      margin: '0 2px',
    },
  },
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },

  assets: {
    logo,
    logoWhite,
    favicon,
    banner,
  },
  currency: {
    default: 'USD',
    defaultWithSymbol: 'USD $',
    available: {
      USD: 'USD $',
      AED: 'AED د.إ',
      INR: 'INR ₹',
      EUR: 'EUR €',
      SAR: 'SAR ﷼',
      KWD: 'KWD د.ك',
    },
  },
  language: {
    default: 'en',
    available: ['en'],
  },
};
